<style scoped lang="less">
  .header {
    background-image: url('/static/images/mobile/salary/1.png');
    background-color: #FFF;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 18px 10px 20px 10px;
    box-sizing: border-box;
    color: #FFF;
    .name {
      font-size: 24px;
      font-weight: 500;
      line-height: 33px;
      color: #FFFFFF;
    }
    .month {
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
    }
    .money {
      margin-top: 6px;
      font-size: 28px;
      font-weight: 500;
      line-height: 50px;
    }
    .tabs {
      display: flex;
      & > span {
        position: relative;
        & + span {
          margin-left: 16px;
        }
        &.active {
          &::after {
            display: block;
            width: 50%;
            position: absolute;
            bottom: -8px;
            content: '';
            height: 4px;
            border-radius: 4px;
            background: #FFC3CE;
          }
        }
      }
    }
  }
  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  ul {
    background-color: #FFF;
    li {
      color: #343434;
      line-height: 60px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ECECEC;
      padding: 0 25px;
    }
  }
</style>

<template>
  <div class="mobile-page-salary">
    <div class="header">
      <div class="name">Hello, {{userInfo.name}}</div>
      <div style="display: flex;align-items: center;margin: 20px 0;justify-content: space-between;">
        <div>
          <div class="month">{{monthText}}月工资</div>
          <!-- <div class="money">
            ¥
            <span v-if="active === 1" key="yf" data-precision="2" v-num-to="data.yf || ''"></span>
            <span v-else key="sf" data-precision="2" v-num-to="data.sf || ''"></span>
          </div> -->
        </div>
        <div style="display: flex;align-items: center;background: #FFF;border-radius: 8px;padding: 16px;">
          <img src="/static/images/mobile/salary/2.png" style="width: 50px;"/>
          <div style="display: flex;align-items: center;flex-direction: column;margin-left: 20px;">
            <span style="color: #4B4B4B;font-size: 16px;">图表展示数据</span>
            <span style="padding: 0 9px;margin-top: 9px;text-align: center;border-radius: 10px;background: linear-gradient(90deg, #FFB4C2 0%, #FF839A 100%);">图表切换</span>
          </div>
        </div>
      </div>
      <!-- <div class="tabs">
        <span @click="active = 1" :class="{active: active === 1}">应发工资</span>
        <span @click="active = 2" :class="{active: active === 2}">实发工资</span>
      </div> -->
    </div>
    <ul v-if="data">
      <li v-for="column in columnList" :key="column.field">
        <span>{{column.title}}</span>
        <span data-precision="2">{{data[column.field]}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { wageRequest } from '../../api'
import {
  getLastMonth
} from '@/syslib/tools'
export default {
  data () {
    return {
      active: 1,
      data: {}
    }
  },
  computed: {
    userInfo () {
      return this.$store.getters.userInfo
    },
    month () {
      return getLastMonth()
    },
    monthText () {
      return this.month ? Number(this.month.slice(5, 7)) : ''
    },
    columnList () {
      let data = []
      let dataMap = this.data.sy
      dataMap = dataMap ? JSON.parse(dataMap) : {}
      Object.keys(dataMap).forEach(key => data.push({
        field: '__' + key,
        title: key,
        sort: true
      }))
      return data
    }
  },
  methods: {
    async loadData () {
      const data = await wageRequest.get({
        month: this.month
      })
      data.forEach((v) => {
        let dataMap = v.sy
        dataMap = dataMap ? JSON.parse(dataMap) : {}
        Object.keys(dataMap).forEach(key => v['__' + key] = dataMap[key])
      })
      if (data.length) {
        this.data = data.pop()
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
